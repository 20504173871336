import * as React from 'react';
import { Link, graphql } from 'gatsby';
import { useQuery } from '@apollo/react-hooks';
import { Router } from '@reach/router';
import gql from 'graphql-tag';

import { IndexViewerQueryQuery, AdminPermission } from '../generated/graphql';
import Page from '../components/common/Page';
import Container from '../components/common/Container';
import IndexLayout from '../components/common/Layout';
import { Route } from '../components/Route';

const indexQuery = gql`
  query indexViewerQuery {
    viewer {
      admin {
        id
        permissions
      }
    }
  }
`;

const Index = () => {
  const { loading, error, data } = useQuery<IndexViewerQueryQuery>(indexQuery);
  if (loading) return <p>Loading...</p>;
  if (error) {
    return <p>{error.message} - Try reloading the website</p>;
  }

  const permissions = data?.viewer?.admin?.permissions || [];
  return (
    <>
      {permissions.includes(AdminPermission.ReadCourseware) && (
        <Link to="/courseware">Courseware</Link>
      )}
      <br />
      {permissions.includes(AdminPermission.ReadUserData) && (
        <Link to="/userLookup">User Lookup</Link>
      )}
      <br />
      {permissions.includes(AdminPermission.ReadUserMessages) && (
        <Link to="/chatMessageSearch">Search chat messages</Link>
      )}
      <br />
      {permissions.includes(AdminPermission.ManageAdmins) && (
        <Link to="/adminLookup">Admin Lookup</Link>
      )}
      <br />
      {permissions.includes(AdminPermission.ReadClassBooking) && (
        <Link to="/bookings">Bookings</Link>
      )}
      <br />
      {permissions.includes(AdminPermission.ManagePromoCodes) && (
        <Link to="/promoCodeLookup">Promo Codes</Link>
      )}
    </>
  );
};

export const query = graphql`
  query SiteVerionQuery {
    versionInfo {
      version
    }
  }
`;

const IndexPage = ({ data }: any) => (
  <IndexLayout>
    <Page>
      <Container>
        <Router primary>
          <Route path="/" component={Index} />
        </Router>
      </Container>
      <div style={{ position: 'absolute', bottom: '16px' }}>
        Version: {data.versionInfo.version}
      </div>
    </Page>
  </IndexLayout>
);

export default IndexPage;
